.react-google-flight-datepicker .date-picker {
  border-radius: 0.475rem;
  padding: 0;
}

.react-google-flight-datepicker .selected-date {
  font-size: 1.1rem;
}

.react-google-flight-datepicker .date-picker-input {
  border: 1px solid #e4e6ef;
  border-radius: 0.475rem;
  color: #181c32;
  height: 56px;
  letter-spacing: 0.2px;
  font-size: 1.1rem;
  font-weight: 500;
}
