//
// Menu
//

// Aside menu
.aside-menu {
  .menu {
    // Link padding x
    @include menu-link-padding-x(get($aside-config, padding-x));

    // Menu indention
    @include menu-link-indention(get($aside-config, menu-indention), get($aside-config, padding-x));
  }

  // Item
  .menu-item {
    padding: 0;

    // Menu Link
    .menu-link {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }

    // Icon
    .menu-icon {
      justify-content: flex-start;
    }
  }
}

// Aside dark theme
.aside-dark {
  .hover-scroll-overlay-y {
    --scrollbar-space: 0.4rem;

    @include scrollbar-color(
      get($aside-config, scrollbar-color),
      get($aside-config, scrollbar-hover-color)
    );
  }

  .menu {
    .menu-item {
      .menu-section {
        color: #4c4e6f !important;
      }

      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state(#9899ac, #758196, #9899ac, #9899ac, null);
      @include menu-link-hover-state($white, $primary, $primary, $white, #1b1b28);
      @include menu-link-show-state($white, $primary, $primary, $white, #1b1b28);
      @include menu-link-active-state($white, $primary-active, $primary-active, $white, #1b1b28);
    }
  }
}

// Aside light theme
.aside-light {
  .menu {
    transition: $transition-base;
    .menu-item {
      .menu-section {
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: 0.244706px;
        text-transform: uppercase;
        color: #acb1c0 !important;
      }

      .menu-sub {
        background-color: rgba(196, 196, 196, 0.1);
        .menu-item .active {
          border-left-style: solid;
          border-left-width: 4px;
          border-left-color: $primary-active;
          .menu-title {
            color: $primary-active;
            margin-left: -4px;
          }
        }
        .menu-title {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0.301176px;
          color: $primary-active;
        }
      }

      //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color
      @include menu-link-default-state(#333a4d, #758196, #9899ac, #758196, null);
      @include menu-link-hover-state(
        $primary-active,
        $primary-active,
        $primary-active,
        #758196,
        null
      );
      @include menu-link-show-state($white, $primary-active, $primary-active, $white, null);
      @include menu-link-active-state($primary-active, $primary-active, #9899ac, #758196, null);
    }

    .menu-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.301176px;
    }
  }
}
