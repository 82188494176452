//
// Required Label
//

.required {
  &:after {
    content: '*';
    position: relative;
    font-size: large;
    color: $danger;
    padding-left: 0.25rem;
    font-weight: 600;
  }
}
