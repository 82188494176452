.form-label {
  font-style: normal !important;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 100% !important;
  /* identical to box height, or 12px */

  display: flex !important;
  align-items: center !important;
  color: #000000 !important;
  /* Inside auto layout */

  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
}
