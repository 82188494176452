//
// Form Control
//

// Form control
.form-control {
  box-shadow: none !important;
  font-size: 14px;
  font-weight: 400;
  color: $input-color !important;
  padding: 0.5rem 0.75rem;

  // Dropdown shown state
  .dropdown.show > & {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
  }

  // Readonly state
  &[readonly] {
    background-color: $input-readonly-bg;
  }

  // Solid style
  &.form-control-solid {
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    @include placeholder($input-solid-placeholder-color);
    color: $input-solid-color;
    transition: $transition-input;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-solid-bg-focus;
      border-color: $input-solid-bg-focus;
      color: $input-solid-color;
      transition: $transition-input;
    }
  }

  // White style
  &.form-control-white {
    background-color: $body-bg;
    border-color: $body-bg;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $body-bg;
      border-color: $body-bg;
    }
  }

  // Flush
  &.form-control-flush {
    @include input-reset();
  }
}

// Placeholder colors
.placeholder-gray-500 {
  @include placeholder($gray-500);
}

.placeholder-white {
  @include placeholder($white);
}

// Textarea reset resize
.resize-none {
  resize: none;
}


.custom-number-input {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 0;
    cursor: pointer;
  }

  input[type='number'] {
    background-image: url("data:image/svg+xml,%3csvg width='23' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7 15L12 20L17 15M7 9L12 4L17 9' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    background-position: center right;
    background-size: contain;
    background-repeat: no-repeat;
    caret-color: transparent;
  }
}