//
// FormValidation Customization.  More info: https://formvalidation.io/
//

.fv-plugins-message-container {
  margin-top: 0.3rem;

  .fv-help-block {
    color: $danger;
    font-size: 1rem;
    font-weight: 400;
  }

  &.valid-feedback,
  &.invalid-feedback {
    display: block;
    font-weight: 400;
  }
}
// validation alert-image disabled

.form-control.is-invalid,
.form-control.is-valid {
  background-image: none;
  padding-right: 0.8rem;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  padding-right: 0.2rem;
}
