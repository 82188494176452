//
// Badge
//

.badge {
  // Fixed size
  &.badge-circle,
  &.badge-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $badge-size;
    min-width: $badge-size;
    padding: 0 0.1rem;
  }

  // Circle
  &.badge-circle {
    border-radius: 50%;
    padding: 0;
    min-width: unset;
    width: $badge-size;
  }

  // Sizes
  &.badge-sm {
    min-width: $badge-size-sm;
    font-size: $badge-font-size-sm;

    &.badge-circle {
      width: $badge-size-sm;
      height: $badge-size-sm;
    }
  }

  &.badge-lg {
    min-width: $badge-size-lg;
    font-size: $badge-font-size-lg;

    &.badge-circle {
      width: $badge-size-lg;
      height: $badge-size-lg;
    }
  }
}

@each $name, $value in $badge-colors {
  .badge-#{$name} {
    color: theme-inverse-color($name);
    // background-color: $value;
    border-radius: 16px;
    background-color: var(--bs-#{$name}, $value);
    border: 1px solid theme-inverse-color($name);
    // &::before {
    //   content: '\2022';
    //   font-size: 1.3em;
    //   display: inline-flex;
    //   position: relative;
    //   left: 0;
    //   top: 2px;
    //   z-index: 55;
    //   padding-right: 2.5px;
    // }
  }

  .badge-light-#{$name} {
    color: $value;
    background-color: var(--bs-light-#{$name}, $value); //theme-light-color($name);
    border-radius: 16px;
    border-width: 0.1px;
    border: 1px solid var(--bs-#{$name}, $value);
    mix-blend-mode: multiply;

    // &::before {
    //   content: '\2022';
    //   font-size: 1.3em;
    //   display: inline-flex;
    //   position: relative;
    //   left: 0;
    //   top: 2px;
    //   z-index: 55;
    //   padding-right: 2.5px;
    // }
  }
}

.badge-lighten-success {
  background-color: #0bb07b;
  color: #ffffff;
  border-radius: 4px;
}

.badge-light-secondary {
  color: #2a5c87 !important;
  background: #e3f2ff !important;
  border-radius: 16px;
  border-width: 0.1px;
  border: 1px solid #aac6dd;
  mix-blend-mode: multiply;
}

.badge-light-orange {
  color: #fd7e14;
  background-color: #fff7f0 !important;
  border-radius: 16px;
  border-width: 0.1px;
  border: 1px solid var(--bs-orange, #fd7e14);
  mix-blend-mode: multiply;
}

.nav-item .nav-link.border-orange {
  border-bottom: 1px solid var(--bs-orange, #fd7e14) !important;
}
