//
// Footer
//

.welcome-container {
  min-height: 150px;
  background: linear-gradient(269.64deg, $primary-active 0%, #4063d5 100%);
  border-radius: 10px;
  margin-bottom: 42px;
  padding-left: 67px;
  padding-top: 35px;
}

.welcome-container .title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  color: #ffffff;
  min-height: 93px;
  padding-bottom: 20px;
}

.welcome-container .description {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding-right: 188px;
}

.free-trail-bg {
  background-color: #f8f9fb;
}

.free-trail-container {
  background: linear-gradient(269.64deg, #9e2ebb 0%, #4063d5 100%);
  border-radius: 20px;
}

.free-trail-card {
  background: #ffffff;
  box-shadow: 0px 2px 5px #f1f2f4;
}

.free-trail-right-test {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.free-trail-left-container {
  margin-right: 80px;
  color: $white;
}

.free-trail-left-container .greeting {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.5px;
  margin-bottom: 7px;
}

.free-trail-left-container .free-trail-text {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  min-height: 119px;
}

.free-trail-left-container .description {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 9px;
}

.free-trail-left-container .future {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-top: 2%;
}

@include media-breakpoint-down(sm) {
  .free-trail-container {
    padding-top: 10%;
    width: 100%;
    padding: 5%;
    height: 100%;
  }
}

@include media-breakpoint-up(lg) {
  .free-trail-container {
    width: 65%;
    padding: 56px 50px;
    padding-top: 5%;
  }
}

@include media-breakpoint-up(xl) {
  .free-trail-container {
    // width: 100%;
    // padding: 50px;
    // height: 100%;
    width: 100%;
    padding: 56px 50px;
    padding-top: 5%;
  }
}

@include media-breakpoint-up(md) {
  .free-trail-container {
    // width: 85%;
    // height: 90%;
    // padding: 56px 50px;
    width: 100%;
    padding: 56px 50px;
    padding-top: 5%;
  }
}
@include media-breakpoint-up(xxl) {
  .free-trail-container {
    // width: 65%;
    width: 100%;
    height: 65%;
    padding: 56px 50px;
  }
}

.vh-75 {
  height: 75vh !important;
}
