//
// Form Select
//

.form-select {
  box-shadow: none !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
  &:hover {
    background-color: $input-solid-bg;
  }

  // Solid style
  &.form-select-solid {
    @include placeholder($input-solid-placeholder-color);
    background-color: $input-solid-bg;
    border-color: $input-solid-bg;
    color: $input-solid-color;
    transition: $transition-input;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $input-solid-bg-focus;
      border-color: $input-solid-bg-focus !important;
      color: $input-solid-color;
      transition: $transition-input;
    }
  }

  // White style
  &.form-select-white {
    @include placeholder($input-placeholder-color);
    background-color: $body-bg;
    border-color: $body-bg;
    color: $input-color;

    .dropdown.show > &,
    &:active,
    &.active,
    &:focus,
    &.focus {
      background-color: $body-bg;
      border-color: $body-bg !important;
      color: $input-color;
    }
  }
}
